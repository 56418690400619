import {gsap, TweenMax} from 'gsap';

const animator = {
  
  props: {
    show: {
      time: 0.4,
      preDelay: 0.2,
      y: 0,
      scale: 1,
      skewX: 0,
      stagger: 5,
      ease: 'expo.inout'
    },
    hide: {
      time: 0.3,
      preDelay: 0,
      y: -10,
      scale: 0.8,
      skewX: 4,
      ease: 'expo.out'
    },
    title: {
      show: {
        time: 0.4,
        preDelay: 0.2,
        y: 0,
        ease: 'expo.inout'
      },
      hide: {
        time: 0.3,
        preDelay: 0,
        y: -75,
        ease: 'expo.inout'
      },
    }
  },
  
  prepSectionTitle: (item, isMobile = false) => {
    if (!item) return;
    TweenMax.set(item, {
      opacity: 0,
      scale: (!isMobile) ? 1 : 0.5
    })
    return item;
  },
  
  sectionTitleShow: (item, options) => {
    if (!item) return;
    const time = animator.props.show.time;// + (options.velocity / 1000);
    TweenMax.to(item, time, {
      opacity: 1,
      y: animator.props.title.show.y,
      ease: animator.props.title.show.ease,
      delay: animator.props.title.show.preDelay,
      overwrite: false
    })
  },
  
  sectionTitleHide: (item, options) => {
    if (!item) return;
    const y = animator.props.title.hide.y * (options.direction * -1);
    // console.log('/animator/ -sectionTitleHide', y);
  
    TweenMax.to(item, animator.props.hide.time, {
      opacity: 0,
      y,
      ease: animator.props.title.hide.ease,
      delay: animator.props.title.hide.preDelay,
    })
  },
  
  mobileSectionTitleShow: (item, options) => {
    if (!item) return;
    TweenMax.to(item, animator.props.show.time, {
      opacity: 1,
      scale: 1,
      // delay: animator.props.show.preDelay
    });
  },
  
  
  mobileSectionTitleHide: (item, options) => {
    if (!item) return;
    TweenMax.to(item, animator.props.hide.time, {
      opacity: 0,
      scale: 0.8,
      delay: 0
    });
  },
  
  prepContent: (items) => {
    if (!items) return;
    const eligibleNodes = [...items.querySelectorAll('[data-animate]')];
    const animNodes = [];
    eligibleNodes.map((item, index) => {
      if (item.dataset.animate === 'self') {
        // console.log('/animator/ -SELF', item);
        animNodes.push(item);
      }
      if (item.dataset.animate === 'children') {
        [...item.children].map((childItem) => {
          animNodes.push(childItem);
        })
      }
    });
    animator.setInitialState(animNodes);
    return animNodes;
  },
  
  
  setInitialState: (items) => {
    if (!items) return;
    items.map((item, index) => {
      const skewX = (index % 2 === 0) ? animator.props.hide.skewX : -animator.props.hide.skewX;
      TweenMax.set(item, {
        opacity: 0,
        scale: animator.props.hide.scale,
        y: animator.props.hide.y,
        skewX
      })
    })
  },
  
  contentShow: (items) => {
    if (!items) return;
    items.map((item, index) => {
      const delay = animator.props.show.preDelay + ((index / items.length) / animator.props.show.stagger);
      TweenMax.to(item, animator.props.show.time, {
        opacity: 1,
        scale: animator.props.show.scale,
        y: animator.props.show.y,
        skewX: animator.props.show.skewX,
        ease: animator.props.show.ease,
        delay,
        overwrite: false
      })
    });
  },
  
  contentHide: (items) => {
    if (!items) return;
    items.map((item, index) => {
      const skewX = (index % 2 === 0) ? animator.props.hide.skewX : -animator.props.hide.skewX;
      const delay = 0;
      TweenMax.to(item, animator.props.hide.time, {
        opacity: 0,
        scale: animator.props.hide.scale,
        y: animator.props.hide.y,
        skewX,
        delay,
        ease: animator.props.show.ease
      })
    });
  },
}

module.exports = animator;
