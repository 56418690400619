import mediator from './mediator';
import Header from './Header';
import HeaderMobile from './HeaderMobile';
import Splash from './Splash';

const App = {};

App.init = () => {
  
  console.log('/app/ --release::16/12/20');
  mediator.startUpScrollPosition();
  
  // TODO prevent running headers simultaneously
  const header = Header();
  header.init();
  
  const headerMobile = HeaderMobile();
  headerMobile.init();
  
  const splash = Splash();
  splash.init();
  
  mediator.initViews();
  mediator.watchViewChange();
  mediator.watchBreakpoint();
}

App.init();

module.exports = App;
