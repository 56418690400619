import {gsap, TweenMax} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import signal from 'signal-js';

const Splash = () => {
  
  const els = {
    el: document.body.querySelector('.splash'),
    bg: null,
    inner: null,
    logo: null,
    arrow: null
  }
  
  const props = {
    animation: {
      preDelay: 1,
      time: 1.1,
      ease: 'expo.inout',
      scale: 1.5,
      x: '100vw',
      y: '-50px'
    }
  }
  
  const init = () => {
    els.viewBg = els.el.querySelector('.splash__bg');
    els.bg = els.el.querySelector('.splash__inner__bg');
    els.inner = els.el.querySelector('.splash__inner');
    els.logo = els.el.querySelector('.splash__logo');
    els.arrow = els.el.querySelector('.splash__arrow-wrapper');
    
    prepIntroAnimation();
    introAnimation();
  }
  
  const prepIntroAnimation = () => {
  
    TweenMax.set([els.viewBg], {
      scale: 1.7,
      // opacity: 0
    });
    
    TweenMax.set([els.bg], {scale: props.animation.scale});
    TweenMax.set([els.logo], {
      x: props.animation.x,
      skewX: -30,
      opacity: 0
    });
    TweenMax.set([els.arrow], {
      y: props.animation.y,
      opacity: 0,
      // scale: 2
    });
  
    signal.emit('sg_on-ready', props.uid);
  }
  
  const introAnimation = () => {
    
    const delay = props.animation.preDelay;
  
    TweenMax.to(els.viewBg, props.animation.time, {
      scale: 1,
      opacity: 1,
      ease: 'sine.out',
      delay: delay * 1.1
    });
    
    TweenMax.to(els.bg, props.animation.time * 1.2, {
      scale: 1,
      ease: 'back.out',
      delay
    });
  
    TweenMax.to(els.arrow, props.animation.time, {
      y: 0,
      opacity: 1,
      scale: 1,
      ease: props.animation.ease,
      delay: delay
    });
  
    TweenMax.to(els.logo, props.animation.time / 1.1, {
      x: 0,
      skewX: 0,
      opacity: 1,
      ease: 'expo.out',
      delay
    });

  }
  
  ScrollTrigger.create({
    trigger: els.el,
    start: 'top top',
    end: 'bottom top',
    onUpdate: (self) => {
      if (els.inner) {
        let y = self.progress * 275;
        let o = (100 - (self.progress * 100)) / 100;
        // els.inner.style.transform = `translateY(${-y}px)`;
        // els.arrow.style.transform = `translateY(${-y}px)`;
        els.arrow.style.opacity = o;
        els.viewBg.style.transform = `translateY(${y}px)`;
        els.logo.style.transform = `translateY(${-y / 2}px)`;
      }
    },
    onEnter: (self) => {
      signal.emit('sg_on-view-show', 'home');
    },
    onEnterBack: (self) => {
      signal.emit('sg_on-view-show', 'home');
    },
  })
  
  return {
    init
  }
  
}

module.exports = Splash;
