import {gsap} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import signal from 'signal-js';

import animator from './animator';

// *** stubbed - TODO
const AbstractView = (vo) => {
  
  const props = {
    uid: vo.uid,
    animation: {
      showDelay: 0.3,
      showTime: 0.5,
      hideTime: 0.1,
      defaults: {
        marginTop: 90
      }
    }
  };
  
  const els = {
    el: vo.el,
    sectionTitle: vo.el.querySelector('.section-title'),
    mobileSectionTitle: vo.el.querySelector('.mobile__title'),
    content: vo.el.querySelector('.content'),
    animNodes: null
  };
  
  const init = () => {
    //// console.log('/AbstractView/ -init', els.content, els.el.id);
    els.animNodes = animator.prepContent(els.content);
    els.sectionTitle = animator.prepSectionTitle(els.sectionTitle);
    els.mobileSectionTitle = animator.prepSectionTitle(els.mobileSectionTitle, true);
  };
  
  const show = (scroller) => {
    // document.body.classList.add(els.el.id);
    signal.emit('sg_view-changed', {
      id: els.el.id,
      isActive: true
    });
    animator.contentShow(els.animNodes, {});
    animator.sectionTitleShow(
        els.sectionTitle, {
          direction: scroller.direction,
          velocity: scroller.getVelocity()
        });
    animator.mobileSectionTitleShow(els.mobileSectionTitle);
  }
  
  const hide = (scroller) => {
    // document.body.classList.remove(els.el.id);
    signal.emit('sg_view-changed', {
      id: els.el.id,
      isActive: false
    });
    animator.contentHide(els.animNodes, {});
    animator.sectionTitleHide(
        els.sectionTitle, {
          direction: scroller.direction,
          velocity: scroller.getVelocity()
        });
    animator.mobileSectionTitleHide(els.mobileSectionTitle);
  }
  
  const notifyIsActive = () => {
    signal.emit('sg_on-view-show', props.uid);
  }
  
  // *** notification scroller
  ScrollTrigger.create({
    trigger: els.el,
    start: 'top-=50px',
    end: 'top+=100vh',
    onEnter: () => {
      notifyIsActive();
    },
    onEnterBack: () => {
      notifyIsActive();
    },
  });
  
  // *** animation scroller
  ScrollTrigger.create({
    trigger: els.content,
    start: 'top bottom',
    end: 'bottom top',
    onEnter: (self) => {
      show(self);
    },
    onEnterBack: (self) => {
      show(self);
    },
    onLeave: (self) => {
      hide(self);
    },
    onLeaveBack: (self) => {
      hide(self);
    }
  });
  
  // *** api
  return {
    init,
    show,
    hide
  }
}

module.exports = AbstractView;
