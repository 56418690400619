import AbstractView from './AbstractView';
import signal from 'signal-js';
import {gsap, TweenMax} from 'gsap';


const mediator = {};
mediator.props = {
  currentViewport: null,
  breaks: {
    mobile: 768,
    tablet: 1024
  }
};
mediator.els = {
  body: document.body,
  viewport: document.body.querySelector('.viewport')
}
mediator.views = [];

/**
 * ensure onload takes us to anchor position if set in the url
 * didn't want to use a timeout but seems to work regardless of network conditions
 */
mediator.startUpScrollPosition = () => {
  window.scroll(0,0);
  const html = document.querySelector('html');
  
  const run = () => {
    const hash = window.location.hash;
    if (hash) {
      document.body.querySelector(hash).scrollIntoView({behavior: 'auto', block: 'start'});
    } else {
      document.body.scrollIntoView({behavior: 'auto', block: 'start'});
    }
    
    TweenMax.to(mediator.els.viewport, 1, {
      opacity: 1,
      delay: 0,
      onComplete: () => {
        html.style.scrollBehavior = 'smooth';
      }
    });
  }
  
  window.setTimeout(() => {
    run();
  }, 333);
}



mediator.watchBreakpoint = () => {

  window.addEventListener('resize', () => {
    onResize();
  });
  
  const onResize = () => {
    let mobile = window.matchMedia(
        `(max-width: ${mediator.props.breaks.mobile}px)`);
    let tablet = window.matchMedia(
        `(min-width: ${mediator.props.breaks.mobile + 1}px) and (max-width: ${mediator.props.breaks.tablet}px)`);
    
    mediator.props.currentViewport = (mobile.matches) ? 'mobile' : (tablet.matches) ? 'tablet' : 'desktop';
    mediator.els.body.classList = '';
    mediator.els.body.classList.add(`${mediator.props.currentViewport}`);
    signal.emit('sg_viewport-changed', mediator.props.currentViewport);
    // TODO debounce?
  }
  // *** first time
  onResize();
};

mediator.watchViewChange = () => {
  signal.on('sg_view-changed', (vo) => {
    mediator.els.viewport.classList = 'viewport';
    if (vo.isActive) {
      mediator.els.viewport.classList.add(vo.id);
    }
  });
};

mediator.initViews = () => {
  const views = [...mediator.els.body.querySelectorAll('.view')];
  mediator.views = views.map((item) => {
    const view = {
      class: AbstractView({
        uid: item.dataset.uid,
        el: item,
      })
    }
    view.class.init();
    return view;
  });
  
  //// console.log('/utils/ -initViews', mediator.views);
}



module.exports = mediator;
