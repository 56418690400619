import {gsap, TweenMax} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import signal from 'signal-js';


const HeaderMobile = () => {
  
  const els = {
    el: document.body.querySelector('.header--mobile'),
    bg: null,
    logo: null,
    nav_links: null,
    hamburger: null
  }
  
  const state = {
    navIsActive: false,
    bgIsActive: false
  }
  
  const init = () => {
    els.bg = els.el.querySelector('.header--mobile__bg');
    els.logo = els.el.querySelector('.header--mobile__logo');
    els.nav_links = [...els.el.querySelectorAll('.nav__link')];
    els.nav_links.push(els.el.querySelector('.header--mobile__logo'));
    els.hamburger = els.el.querySelector('.hamburger');
    els.nav = els.el.querySelector('.nav--mobile__wrapper');
    
    els.hamburger.addEventListener('click', (e) => {
      console.log('/HeaderMobile/ -CLICK');
      toggleNav();
    });
    
    signal.on('sg_on-view-show', (uid) => {
      highlightItem(uid);
    });
  
    toggleNav(true);
    tweenUIState(0);
  }
  
  const toggleNav = (forceClose = false) => {
    
    if (!els.nav) return;
    
    if (forceClose) {
      els.nav.style.height = 0;
      state.navIsActive = false;
      return;
    }
    
    state.navIsActive = !state.navIsActive;
    
    if (!state.bgIsActive) {
      tweenUIState(1);
    }
    
    let h = (state.navIsActive) ? 'auto' : 0;
    els.nav.style.height = h;
  }
  
  const highlightItem = (uid) => {
    els.nav_links.map((item) => {
      if (item.dataset.uid === uid && uid != 'home') { // *** 'home'... hacky - should be declared in css
        // console.log('/HeaderMobile/ -', uid);
        item.classList.add('is-active');
      } else {
        item.classList.remove('is-active');
      }
    })
  }
  
  const tweenUIState = (int) => {
    if (!els.bg || !els.logo) return;
    
    state.bgIsActive = !!int;
  
    TweenMax.to(els.bg, 0.3, {
      opacity: int
    });
    TweenMax.to(els.logo, 0.3, {
      opacity: int
    })
  }
  
  ScrollTrigger.create({
    trigger: document.body.querySelector('.viewport'),
    start: '0%',
    end: '100%',
    onToggle: (self) => {
      tweenUIState(+self.isActive);
    },
    onUpdate: (self) => {
      toggleNav(true);
    }
  })
  
  return {
    init
  }
}

module.exports = HeaderMobile;
