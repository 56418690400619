import {gsap, TweenMax} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import signal from 'signal-js';


const Header = () => {
  
  const els = {
    el: document.body.querySelector('.header'),
    bg: null,
    logo: null,
    nav_links: null
  }
  
  const init = () => {
    els.bg = els.el.querySelector('.header__bg');
    els.logo = els.el.querySelector('.header__logo');
    els.nav_links = [...els.el.querySelectorAll('.nav__link')];
    els.nav_links.push(els.el.querySelector('.header__logo'));

    signal.on('sg_on-view-show', (uid) => {
      highlightItem(uid);
    });
  
    tweenUIState(0);
  }
  
  const highlightItem = (uid) => {
    els.nav_links.map((item) => {
      // //// console.log('/Header/ -HIGHLIGHT', item.dataset.uid, uid);
      
      if (item.dataset.uid === uid) {
        //// console.log('.../Header/ -HIGHLIGHT', uid);
        item.classList.add('is-active');
      } else {
        item.classList.remove('is-active');
      }
      
    })
  }
  
  const tweenUIState = (int) => {
    if (!els.bg || !els.logo) return;
  
    TweenMax.to(els.bg, 0.3, {
      opacity: int
    });
    TweenMax.to(els.logo, 0.3, {
      opacity: int
    })
  }
  
  ScrollTrigger.create({
    trigger: document.body.querySelector('.viewport'),
    start: '0%',
    end: '100%',
    onToggle: (self) => {
      tweenUIState(+self.isActive);
    },
    onUpdate: (self) => {}
  })
  
  return {
    init
  }
}

module.exports = Header;
